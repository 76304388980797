import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        component: () =>
            import ('../views/About.vue')
    },
    {
        path: '/our-works',
        name: 'OurWorks',
        component: () =>
            import ('../views/OurWorks.vue')
    },
    {
        path: '/detail-work',
        name: 'OurWorksDetail',
        props: true,
        component: () =>
            import ('../components/DetailWork.vue')
    },
    {
        path: '/our-service',
        name: 'OurService',
        component: () =>
            import ('../views/OurService.vue')
    },
    {
        path: '/insight',
        name: 'Insight',
        component: () =>
            import ('../views/Insight.vue')
    },
    {
        path: '/insight/:article_name',
        name: 'InsightDetail',
        props: true,
        component: () =>
            import ('../components/DetailInsight.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () =>
            import ('../views/Contact.vue')
    },
    { path: '*', redirect: '/' }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router