<template>
  <div id="home" class="app" >
    
    <Header/>
    <!-- Content -->
    <div class="container-fluid pt-5" style="background-color: #FE9800;">
        <!-- <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
                <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
            </ol>
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-12 col-lg-6">
                                <div class="row">
                                    <div class="col-12">
                                        <img src="../assets/images/home/why.png" class="img-mkt"><br>
                                    </div>
                                    <div class="col-12 col-md-12 col-lg-8  mt-2" style="text-align: justify;">
                                        <div>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col mt-2">
                                        <button type="button" class="btn" style="background-color: #0077E5;color: white;">Learn More</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <img src="../assets/images/home/people.png" class="img-mkt2">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="container my-lg-5">
                        <div class="row align-items-center">
                            <div class="col-12 col-lg-6">
                                <div class="row">
                                    <div class="col-12">
                                        <img src="../assets/images/home/why-need.png" class="img-mkt"><br>
                                    </div>
                                    <div class="col-12 col-md-12 col-lg-8 mt-2" style="text-align: justify;">
                                        <div>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col mt-2">
                                        <button type="button" class="btn" style="background-color: #0077E5;color: white;">Learn More</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <img src="../assets/images/home/people2.png" width="100%">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div> -->
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-lg-6">
                    <div class="row">
                        <div class="col-12">
                            <img src="../assets/images/home/why.png" class="img-mkt"><br>
                        </div>
                        <div class="col-12 col-md-12 col-lg-8  mt-2" style="text-align: justify;">
                            <div>You need Digital Marketing to get to know your audience and allow them to know you personally which can help to create brand loyalty. It also needed to reach an enormous audience in a way that is both cost-effective and measurable.</div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <img src="../assets/images/home/people.png" class="img-mkt2">
                </div>
            </div>
        </div>

    </div>

    <div class="container-fluid" style="background-color: #005DB4;">
        <div class="container pb-5">
            <div class="row align-items-center">
                <div class="col-12 col-lg-6">
                    <img src="../assets/images/home/about-makuta.png" class="img-about"><br>
                    <hr style="border: 6px solid white;margin-left: -200px;">
                </div>
                <div class="col mt-0 mt-md-0 mt-lg-5" style="text-align: justify;">
                    <div>Makuta  Communication will help you grow your business bigger and stronger by using digital channels to reach customers, build brand awareness, and promote products and services. At a high level, digital marketing refers to advertising delivered through digital channels such as search engines, websites, social media, email, mobile apps, and many more.</div>
                    <a ><router-link to="/about"><img src="../assets/images/button/read-more.png" class="mt-5" alt="" width="120px"></router-link></a>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid pt-lg-4 our-service">
        <div class="container">
            <div class="row align-items-center">
                <div class="col">
                    <img src="../assets/images/home/our-service.png" class="img-our-service"><br>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-12 col-lg-5" style="text-align: justify;">
                    <div>Our Digital Services include Website <strong> Design, SEO, Paid Search, Social Media, Content Marketing, Analytic Reporting, ERP & CRM.</strong>
                     <br> <br>
                    Meanwhile our Traditional Services include <strong> Advertising, Brand Identity, Direct Mail, Brochure Design, Event Marketing, Packaging, and Product Launch.</strong> </div>
                    <a><router-link to="/our-service"> <img src="../assets/images/button/read-more-blue.png" alt="" class="mt-3" width="120px"></router-link></a>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid py-5" style="background-color: #eeeeee;">
        <div class="container">
            <div class=" row d-flex justify-content-center">
                <strong><h3 style="color: black; opacity: 0.5; font-weight: 800;">Clients</h3></strong>
            </div>
                <!-- <div class="d-flex justify-content-center">
                    <div class="col">
                        <img src="../assets/images/home/Logo Project/allure-logo.png" alt="" class="img-client">
                    </div>
                    <div class="col">
                        <img src="../assets/images/home/Logo Project/dmansion-logo.png" alt="" class="img-client">
                    </div>
                    <div class="col">
                        <img src="../assets/images/home/Logo Project/gdc-logo.png" alt="" class="img-client">
                    </div>
                    <div class="col">
                        <img src="../assets/images/home/Logo Project/grc-logo.png" alt="" class="img-client">
                    </div>
                    <div class="col">
                        <img src="../assets/images/home/Logo Project/grd-logo.png" alt="" class="img-client">
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <div class="col">
                        <img src="../assets/images/home/Logo Project/gtr-logo.png" alt="" class="img-client">
                    </div>
                    <div class="col">
                        <img src="../assets/images/home/Logo Project/kalindo-logo.png" alt="" class="img-client">
                    </div>
                    <div class="col">
                        <img src="../assets/images/home/Logo Project/oak-logo.png" alt="" class="img-client">
                    </div>
                    <div class="col">
                        <img src="../assets/images/home/Logo Project/ppr-logo.png" alt="" class="img-client">
                    </div>
                    <div class="col">
                        <img src="../assets/images/home/Logo Project/rosewood-logo.png" alt="" class="img-client">
                    </div>
                </div> -->
                <div class="row d-flex justify-content-center">
                    <div class="col-lg-2 col-4 pb-3 align-self-center mx-2">
                        <img src="../assets/images/clients/reka-cipta.png" alt="" class="img-fluid img-client w-100">
                    </div>
                    <div class="col-lg-2 col-4 pb-3 align-self-center mx-2">
                        <img src="../assets/images/clients/dpl.png" alt="" class="img-fluid img-client w-100">
                    </div>
                    <div class="col-lg-2 col-4 pb-3 align-self-center mx-2">
                        <img src="../assets/images/clients/kalindo.png" alt="" class="img-fluid img-client w-100">
                    </div>
                    <div class="col-lg-2 col-4 pb-3 align-self-center mx-2">
                        <img src="../assets/images/clients/andara.png" alt="" class="img-fluid img-client w-100">
                    </div>
                    <div class="col-lg-2 col-4 pb-3 align-self-center mx-2">
                        <img src="../assets/images/clients/logo-waskita.png" alt="" class="img-fluid img-client w-100">
                    </div>
            </div>
        </div>
    </div>

    <footer-page/>
  </div>
</template>

<script>
import FooterPage from '../components/FooterPage.vue'
import Header from '../components/Header.vue'

export default {
  components: { FooterPage, Header },
  name: 'Home',
}
</script>

<style>
.our-service{
    background-image: url('../assets/images/home/bg-our-service.png');
    padding-bottom: 100px;
    background-size: cover;
}
</style>

